.element::-webkit-scrollbar {
    width: 0; /* For Chrome, Safari, and newer Edge */
}

.element {
    scrollbar-width: none; /* For Firefox */
}

.qrCodeImage {
    background-image:  url("./assets/google-qrcode.png");
    background-position: 10px 10px;
    background-repeat: no-repeat;
    background-size: 120px 120px;
    border-radius: 24px;
    box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.18), 0px 1px 3px 0px rgba(255, 255, 255, 0.16) inset;
}

@media screen and (max-width: 899px) {
    .qrCodeImage {
        background-size: 85px 85px;
    }
}
