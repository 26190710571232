body {
  margin: 0;
  height: 100%;
}

html{
  height: 100%;
}

#root {
  height: 100%;
}

@font-face {
  font-family: 'Suisse Intl';
  font-weight: 500;
  src: local('Suisse Intl'), url(assets/fonts/SuisseIntl-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Suisse Intl';
  src: local('Suisse Intl'), url(assets/fonts/SuisseIntl-Regular.ttf) format('truetype');
}
